// canvas 高清渲染设置
export default function HiDpi2Canvas(node) {
  // DPI
  const pixelRatio = (function () {
    const canvas = document.createElement("canvas"),
      context = canvas.getContext("2d"),
      backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;
    return (window.devicePixelRatio || 1) / backingStore;
  })();
  // HTMLCanvasElement.getContext
  node.getContext = (function (_super) {
    return function (type) {
      const context = _super.call(this, type);

      if (type === "2d") {
        if (pixelRatio > 1) {
          this.style.height = this.height + "px";
          this.style.width = this.width + "px";
          this.width *= pixelRatio;
          this.height *= pixelRatio;
        }
      }

      (function (prototype) {
        const forEach = function (obj, func) {
          for (let p in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(p)) {
              func(obj[p], p);
            }
          }
        };

        const ratioArgs = {
          fillRect: "all",
          clearRect: "all",
          strokeRect: "all",
          moveTo: "all",
          lineTo: "all",
          arc: [0, 1, 2],
          arcTo: "all",
          bezierCurveTo: "all",
          isPointinPath: "all",
          isPointinStroke: "all",
          quadraticCurveTo: "all",
          rect: "all",
          translate: "all",
          createRadialGradient: "all",
          createLinearGradient: "all",
        };

        if (pixelRatio === 1) return;

        forEach(ratioArgs, function (value, key) {
          context[key] = (function (_super) {
            return function () {
              var i,
                len,
                args = Array.prototype.slice.call(arguments);

              if (value === "all") {
                args = args.map(function (a) {
                  return a * pixelRatio;
                });
              } else if (Array.isArray(value)) {
                for (i = 0, len = value.length; i < len; i++) {
                  args[value[i]] *= pixelRatio;
                }
              }

              return _super.apply(this, args);
            };
          })(prototype[key]);
        });

        // Stroke lineWidth adjustment
        context.stroke = (function (_super) {
          return function () {
            this.lineWidth *= pixelRatio;
            _super.apply(this, arguments);
            this.lineWidth /= pixelRatio;
          };
        })(prototype.stroke);

        // Text
        //
        context.fillText = (function (_super) {
          return function () {
            var args = Array.prototype.slice.call(arguments);

            args[1] *= pixelRatio; // x
            args[2] *= pixelRatio; // y

            this.font = this.font.replace(
              /(\d+)(px|em|rem|pt)/g,
              function (w, m, u) {
                return m * pixelRatio + u;
              }
            );

            _super.apply(this, args);

            this.font = this.font.replace(
              /(\d+)(px|em|rem|pt)/g,
              function (w, m, u) {
                return m / pixelRatio + u;
              }
            );
          };
        })(prototype.fillText);

        context.strokeText = (function (_super) {
          return function () {
            var args = Array.prototype.slice.call(arguments);

            args[1] *= pixelRatio; // x
            args[2] *= pixelRatio; // y

            this.font = this.font.replace(
              /(\d+)(px|em|rem|pt)/g,
              function (w, m, u) {
                return m * pixelRatio + u;
              }
            );

            _super.apply(this, args);

            this.font = this.font.replace(
              /(\d+)(px|em|rem|pt)/g,
              function (w, m, u) {
                return m / pixelRatio + u;
              }
            );
          };
        })(prototype.strokeText);
      })(CanvasRenderingContext2D.prototype);
      return context;
    };
  })(HTMLCanvasElement.prototype.getContext);

  return node;
}
