<template>
  <div class="PageRenderCheckTrace flex-col">
    <BannerTop
      :iconLeft="['back']"
      :showIconRight="false"
      :title="pageShowName"
    />
    <div class="content flex-col">
      <div class="checkMes flex-row flex-al-center flex-jc-bt">
        <div class="flex-row flex-al-center">
          <div class="teacher flex-center">批阅教师</div>
          <div class="name">{{ teacherName }}</div>
        </div>
        <span v-if="updateTime">批阅时间：{{ updateTime }}</span>
      </div>
      <div class="canvasWrap">
        <canvas
          id="canvas"
          :style="{
            width: canvasWidth + 'px',
            height: canvasHeight + 'px',
          }"
        ></canvas>
        <div
          class="cutMask"
          :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
        >
          <div
            class="cutRect"
            v-for="attache in maskBook.attaches"
            :key="attache.id"
            :style="getPositionSize(attache)"
            @click="previewAnswer(attache)"
          >
            <!--            <div-->
            <!--              v-if="attache.recognitionResult"-->
            <!--              class="checkRes flex-center"-->
            <!--              :class="[resObj[attache.recognitionResult.recognition].class]"-->
            <!--              @click="changeRes(attache)"-->
            <!--            >-->
            <!--              <span>{{-->
            <!--                resObj[attache.recognitionResult.recognition].text-->
            <!--              }}</span>-->
            <!--              <img-->
            <!--                src="https://img01.yzcdn.cn/upload_files/2022/03/17/FoKmliz_QBoFNXR-OGpY3Va9jZ0k.png"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getQuestionInfoByIds,
  listRecognitionForPageHistory,
} from "@/project/smartHomeworkUserClient/API/statistical";
import RenderPointsCanvas2D from "@/project/smartHomeworkUserClient/utils/RenderPointsCanvas2D";
import {
  initZidian,
  getJoinName,
} from "@/project/smartHomeworkUserClient/common/bookName";
import { getMaskBook } from "@/project/smartHomeworkUserClient/API/maskbook";
import BannerTop from "@/components/BannerTop";
import { mapState } from "vuex";
import { getTeacherInfoByIds } from "@/project/smartHomeworkUserClient/API/user";
import { ImagePreview, Toast } from "vant";
import { ossUrl } from "@/static/js/oss";

export default {
  components: { BannerTop },
  data() {
    return {
      query: {},
      maskBook: {
        attaches: [],
      },
      pageShowName: "",
      resObj: {
        right: {
          text: "正确",
          class: "",
        },
        half_right: {
          text: "半对",
          class: "halfRight",
        },
        wrong: {
          text: "错误",
          class: "error",
        },
      },
      canvasHeight: 0,
      canvasWidth: 0,
      recognitionResult: [],
      teacherName: "",
    };
  },
  computed: {
    ...mapState("smartHomeworkUserClient/user", ["studentUserInfo"]),
    updateTime() {
      const result = this.maskBook.attaches[0]?.recognitionResult || {};
      return result.updateTime || "";
    },
  },
  async mounted() {
    const query = this.$route.query;
    this.query = query;
    Toast.loading("加载中...");
    await initZidian();
    const renderPointsCanvas2D = new RenderPointsCanvas2D({
      canvasId: "#canvas",
      canvasWrapId: ".canvasWrap",
      onCanvasViewSizeChange: (w, h) => {
        console.log(w, h);
        this.canvasWidth = w;
        this.canvasHeight = h;
      },
    });
    await this.$store.dispatch(
      "smartHomeworkUserClient/user/getStudentUserInfo"
    );
    const maskBook = await getMaskBook(query.bbPageId);
    this.maskBook = maskBook;
    if (this.maskBook.book) {
      const { joinName } = getJoinName(maskBook.book);
      this.pageShowName = joinName + "-" + maskBook.pageName;
    }
    const res = await this.refreshRecognitionResult();
    let points = res.data
      .map((i) => i.strokes)
      .flat()
      .map((i) => i.points)
      .flat();
    await renderPointsCanvas2D.drawPoints(points);
    points = null;
    Toast.clear();
  },
  methods: {
    refreshRecognitionResult() {
      return listRecognitionForPageHistory({
        ...this.studentUserInfo,
        ...this.query,
      }).then((res) => {
        if (res.status === 200) {
          let teacherId = "";
          const attaches = this.maskBook.attaches || [];
          res.data.forEach((i) => {
            const attache = attaches.find((j) => j.questionId === i.questionId);
            if (attache && i.recognition) {
              attache.recognitionResult = i;
              teacherId = i.teacherId;
            }
          });
          this.maskBook.attaches = [...attaches];
          this.getTeacherName(teacherId);
        }
        return res;
      });
    },
    getPositionSize(attache) {
      return {
        left: (attache.ltx * 100) / attache.pageWidth + "%",
        top: (attache.lty * 100) / attache.pageHeight + "%",
        width: (attache.width * 100) / attache.pageWidth + "%",
        height: (attache.height * 100) / attache.pageHeight + "%",
      };
    },
    getTeacherName(teacherId) {
      if (!teacherId) return;
      return getTeacherInfoByIds([teacherId]).then((res) => {
        this.teacherName = res.data[0].realName;
      });
    },
    changeRes(attache) {
      this.$refs.EditCheckRes.open(attache).then((res) => {
        if (res) {
          this.refreshRecognitionResult();
        }
      });
    },
    previewAnswer(attach) {
      console.log(attach);
      const basicQuestionIds = attach.basicQuestionIds || [];
      if (basicQuestionIds.length < 1) return;
      getQuestionInfoByIds({
        ids: basicQuestionIds.join(","),
      }).then((res) => {
        if (res.status === 200) {
          const imgs = res.data
            .map((i) => this.getCutImages(i.attaches))
            .flat();
          console.log(imgs);
          if (imgs.length > 0) {
            ImagePreview(imgs);
          }
        }
      });
    },
    getCutImages(attaches = []) {
      return attaches.map((attache) => {
        return (
          `${ossUrl(
            attache.page.picPath || attache.page.answerPicPath
          )}?x-oss-process=image/crop,` +
          `x_${parseInt(attache.ltx)},` +
          `y_${parseInt(attache.lty)},` +
          `w_${parseInt(attache.width)},` +
          `h_${parseInt(attache.height)}`
        );
      });
    },
  },
};
</script>

<style lang="scss">
.PageRenderCheckTrace {
  height: 100vh;
  background: linear-gradient(180deg, #ecf2f7 0%, rgba(233, 244, 254, 0) 100%);
  .suxue-bannerTop_title {
    max-width: 300px;
    overflow-x: scroll;
    margin-left: 40px;
    justify-content: flex-start;
  }
  .content {
    flex: 1;
    margin: 18px 14px 18px 14px;
    background: #ffffff;
    border-radius: 6px;
    padding: 8px 9px 11px 9px;
    .checkMes {
      .teacher {
        width: 48px;
        height: 15px;
        background: #f2f8ff;
        border-radius: 2px;
        line-height: 15px;
        text-align: center;
        font-size: 10px !important;
        color: #167ff6;
      }
      .name {
        margin-left: 5px;
        font-size: 12px;
        color: #167ff6;
      }
      span {
        font-size: 10px !important;
        color: #9c9c9c;
        justify-self: flex-end;
      }
    }
    .canvasWrap {
      position: relative;
      flex-grow: 1;
      height: 100%;
      .cutMask {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;

        .cutRect {
          position: absolute;
          border: 1px dashed #4f99ff;

          .checkRes {
            position: absolute;
            top: 0;
            right: 0;
            width: 38px;
            height: 15px;
            background: #4f99ff;
            border-radius: 4px;

            span {
              line-height: 1;
              font-size: 10px;
              color: #ffffff;
              margin-bottom: 0rpx;
            }

            img {
              width: 11px;
            }

            &.error {
              background: #ff7171;
            }

            &.halfRight {
              background: #ffb673;
            }
          }
        }
      }
    }
  }
}
</style>
