import { getMaskBook } from "@/project/smartHomeworkUserClient/API/maskbook";
import { ossUrl } from "@/static/js/oss";
import HiDpi2Canvas from "@/project/smartHomeworkUserClient/utils/HiDpi2Canvas";
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

// 以Canvas2D，渲染底图，绘制点阵
class RenderPointsCanvas2D {
  canvasId = "";
  bgImage = "";
  canvasWrapId = "";

  canvas = null;
  ctx = null;
  canvasWidth = "";
  canvasHeight = "";
  zoom = 1;

  BgImgSize = {};
  onCanvasViewSizeChange = noop;

  currentPageId = "";
  isIniting = false;
  waitingDrawPoints = [];

  constructor(options = {}) {
    const {
      canvasId,
      bgImage,
      canvasWrapId,
      onCanvasViewSizeChange = noop,
    } = options;
    this.canvasId = canvasId;
    this.bgImage = bgImage;
    this.canvasWrapId = canvasWrapId;
    this.onCanvasViewSizeChange = onCanvasViewSizeChange;
    this.initCanvasNode().then(() => {
      if (bgImage) {
        this.initCanvasByBgImg(bgImage);
      }
    });
  }

  changeBgImage(url) {
    return this.initCanvasByBgImg(url);
  }

  onCanvasViewSizeChange(fn) {
    this.onCanvasViewSizeChange = fn;
  }

  async initCanvasNode() {
    // canvas信息
    const CanvasNode = await RenderPointsCanvas2D.adapter.getNodeSize(
      this.canvasId
    );
    const canvas = CanvasNode.node;
    const ctx = canvas.getContext("2d");
    this.canvas = canvas;
    this.ctx = ctx;
    return { canvas, ctx };
  }

  // 画布初始化执行
  async initCanvasByBgImg(bgImg) {
    if (this.isIniting) return;
    this.isIniting = true;
    // 系统信息
    const systemInfo = RenderPointsCanvas2D.adapter.getSystemInfoSync(this.ctx);
    // 图片信息
    const BgImgSize = await RenderPointsCanvas2D.adapter.getImageInfo(bgImg);
    // canvas容器信息
    const CanvasWrapNode = await RenderPointsCanvas2D.adapter.getNodeSize(
      this.canvasWrapId
    );
    // canvas信息
    const { canvas, ctx } = this;

    this.BgImgSize = BgImgSize;

    // 计算合适的视图尺寸
    const { c_width, c_height } = this.getCanvasViewSize(
      BgImgSize.width,
      BgImgSize.height,
      CanvasWrapNode.width,
      CanvasWrapNode.height
    );

    // 设置视图上的尺寸
    this.canvasWidth = c_width;
    this.canvasHeight = c_height;
    this.onCanvasViewSizeChange(c_width, c_height, this);
    // canvas内部尺寸
    const dpr = systemInfo.pixelRatio;

    canvas.width = c_width * dpr;
    canvas.height = c_height * dpr;

    ctx.clearRect(0, 0, c_width, c_height);

    // 绘制底图
    const img = await this.createCanvasImageInWxChatMini(canvas, bgImg);
    ctx.drawImage(img, 0, 0, c_width * dpr, c_height * dpr);
    // 缩放以增强画质，展示最佳尺寸
    // ctx.scale(dpr, dpr);
    // ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
    this.isIniting = false;
  }

  createCanvasImageInWxChatMini(canvas, src) {
    return new Promise((resolve, reject) => {
      const img = RenderPointsCanvas2D.adapter.getCanvasImageClass(canvas);
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = src;
    });
  }

  // 获取canvas视图上的尺寸，由底图和容器共同决定最佳展示尺寸
  getCanvasViewSize(imgWidth, imgHeight, containerWidth, containerHeight) {
    const systemInfo = RenderPointsCanvas2D.adapter.getSystemInfoSync(this.ctx);
    const width = containerWidth || systemInfo.windowWidth;
    const height = containerHeight || systemInfo.windowHeight || width * 3;
    const img_Width = imgWidth;
    const img_Height = imgHeight;

    let c_width = 0;
    let c_height = 0;
    const wh = width / height;
    const scwh = img_Width / img_Height;
    if (wh < scwh) {
      c_width = width;
      c_height = (width * img_Height) / img_Width;
    } else {
      c_width = (height * img_Width) / img_Height;
      c_height = height;
    }
    const viewSize = {
      c_width,
      c_height,
    };
    return viewSize;
  }

  async drawPoints(points) {
    const context = this.ctx;
    for (let i = 0; i < points.length; i++) {
      // 页码
      const page_id = points[i].page_id;

      // 换页需要切换底图，清空当前已绘制内容
      if (page_id !== this.currentPageId) {
        this.waitingDrawPoints.push(points[i]);
        const url = await this.getImageUrl(page_id);
        await this.initCanvasByBgImg(url);
        this.currentPageId = page_id;
      }

      // 如果存在待画的点，先行绘制。
      if (this.waitingDrawPoints.length > 0 && !this.isIniting) {
        const waitingDrawPoints = [...this.waitingDrawPoints];
        this.waitingDrawPoints = [];
        await this.drawPoints(waitingDrawPoints);
      }

      // 开始绘制
      const x = points[i].x;
      const y = points[i].y;
      const lastPointX = this.lastPointX;
      const lastPointY = this.lastPointY;
      // 笔迹宽度
      let lineWidth = this.getAutoStrokeWidth(points[i].width);
      if (Number(lineWidth) > 0 && Number(lineWidth) < 0.01) {
        lineWidth = this.getAutoStrokeWidth("0.1");
      }

      // 纸张类型
      // const paper_type = points[i].paper_type
      // 是否是落笔
      const stroke_start = points[i].stroke_start;
      // 是否是抬笔
      const islast = points[i].stroke_end;
      // const time_stamp = points[i].time_stamp
      // console.log(`${mode} 坐标:(${x},${y}); 线宽:${lineWidth}; 页码:${page_id}; 纸张:${paper_type}; 时间戳:${time_stamp}; 落笔:${stroke_start};抬笔:${islast};`);
      // console.log(context);
      context.strokeStyle = "#000000";
      context.lineCap = "round";
      context.lineJoin = "round";
      if (islast) {
        this.lastPointX = undefined;
        this.lastPointY = undefined;
        continue;
      }

      context.lineWidth = lineWidth;
      const pointX =
        (this.transDpiSize(x) * this.canvasWidth * this.zoom) /
        this.BgImgSize.width;
      const pointY =
        (this.transDpiSize(y) * this.canvasHeight * this.zoom) /
        this.BgImgSize.height;
      if (
        (lastPointX === undefined && lastPointY === undefined) ||
        stroke_start
      ) {
        context.moveTo(pointX, pointY);
      } else if (lastPointX && pointX) {
        context.beginPath();
        context.moveTo(lastPointX, lastPointY);
        context.lineTo(pointX, pointY);
      }
      this.lastPointX = pointX;
      this.lastPointY = pointY;
      context.stroke();

      // if (this.delay) {
      //     await new Promise(resolve => {
      //         setTimeout(() => {
      //             resolve()
      //         }, this.delay)
      //     })
      // }
    }
  }

  async getImageUrl(pageId) {
    const maskBook = await getMaskBook(pageId);
    this.zoom = Number(maskBook.zoom);
    return (
      ossUrl(maskBook.picPath) ||
      "https://dev.oss.leziedu.com/maskbook/61478.png"
    );
  }

  getAutoStrokeWidth(width) {
    const widthRate = 2;
    return (
      ((Number(width) * this.canvasWidth) / this.BgImgSize.width) * widthRate
    );
  }

  transDpiSize(size, toReal) {
    const densityDPI = 300 / 25.4; // 300 DPI
    if (toReal) {
      return size / densityDPI; // 像素转mm
    } else {
      return size * densityDPI; // mm转像素
    }
  }

  CanvasCutDataArray = null;

  // 绘制切题框
  drawCut() {
    const ctx = this.ctx;
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";
    ctx.beginPath();
    ctx.setLineDash([10, 8]);
    ctx.moveTo(20, 20);
    ctx.rect(20, 20, 375, 100);
    ctx.stroke();
  }
}

// const wxAdapter = {
//   getSystemInfoSync() {
//     return wx.getSystemInfoSync;
//   },
//   getImageInfo(url) {
//     return new Promise((resolve, reject) => {
//       wx.getImageInfo({
//         src: url,
//         success: (res) => {
//           console.log(res);
//           resolve(res);
//         },
//         fail(res) {
//           reject(res);
//         },
//       });
//     });
//   },
//   //获取容器的宽高
//   getNodeSize(selector) {
//     return new Promise((resolve, reject) => {
//       const query = wx.createSelectorQuery();
//       query
//         .select(selector)
//         .fields({
//           node: true,
//           size: true,
//         })
//         .exec((res) => {
//           resolve(res[0]);
//         });
//     });
//   },
// getCanvasImageClass(canvas){
//   return canvas.createImage()
// }
// };

const webAdapter = {
  getSystemInfoSync(ctx) {
    const backingStore =
      ctx.backingStorePixelRatio ||
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;

    const pixelRatio = (window.devicePixelRatio || 1) / backingStore;

    return {
      pixelRatio: pixelRatio,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  getImageInfo(url) {
    return new Promise(function (resolve, reject) {
      const image = new Image();
      image.onload = function () {
        resolve({
          width: image.width,
          height: image.height,
        });
      };
      image.onerror = function () {
        reject();
      };
      image.src = url;
    });
  },
  //获取容器的宽高
  getNodeSize(selector) {
    return new Promise((resolve, reject) => {
      const el = window.document.querySelector(selector);
      const size = el.getBoundingClientRect();

      resolve({
        width: size.width,
        height: size.height,
        node: HiDpi2Canvas(el),
      });
    });
  },
  getCanvasImageClass(canvas) {
    return new Image();
  },
};
RenderPointsCanvas2D.adapter = webAdapter;

export default RenderPointsCanvas2D;
